.layout{
    display: flex;
    padding: 32px;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    align-self: stretch;
}

.example{
    overflow-y: scroll;
}

.example::-webkit-scrollbar{
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.example {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  .hideScrollbar {
    scrollbar-width: none;  /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
  }
  
  .hideScrollbar::-webkit-scrollbar {
    display: none;  /* WebKit */
  }

  .filter.drop-shadow-gold {
    filter: drop-shadow(0.5px 0.5px 1px rgba(0, 0, 0, 0.40));
  }
  
  .filter.drop-shadow-gray {
    filter: drop-shadow(0.5px 0.5px 1px rgba(0, 0, 0, 0.20));
  }