@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Plus_Jakarta';
    src: url('./../public/fonts/PlusJakartaSans-VariableFont_wght.ttf') format('truetype');
    font-weight: 100 900;
    font-stretch: 75% 125%;
    font-style: normal;
}

body {
    @apply font-['Plus_Jakarta'];
}

.hideScrollbar {
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.hideScrollbar::-webkit-scrollbar {
    display: none;
}

.custom-datepicker-container {
    margin-bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    position: relative;
    z-index: 20;
    width: 300px; /* Adjust as needed */
}

.custom-datepicker-input {
    background-size: 20px 20px;
    padding-right: 40px;
    width: 100%;
    
}

.react-tailwindcss-datepicker-popover {
    width: 100px !important; /* Match the container width */
    max-height: 100px !important; /* Adjust the max height as needed */
    overflow-y: auto !important;
    position: absolute !important;
    top: auto !important;
    bottom: 100% !important;
    left: 0 !important;
    margin-bottom: 8px;
    z-index: 1002 !important;
    border: 1px solid red;
}

.react-tailwindcss-datepicker-popover-content {
    padding: 10px !important;
    border: 1px solid red;
}

.react-tailwindcss-datepicker-popover-content .rdp-months {
    width: 50% !important;
    border: 1px solid red;
    
}

.react-tailwindcss-datepicker-popover-content .rdp-month {
    width: 100% !important;
    border: 1px solid red;
}

.react-tailwindcss-datepicker-popover-content .rdp-table {
    width: 50% !important;
    border: 1px solid red;
}

.react-tailwindcss-datepicker-popover-content .rdp-cell {
    height: 40px !important; /* Adjust as needed */
    border: 1px solid red;
}

/* You can add more specific styles here if needed */