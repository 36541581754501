.customScrollbar {
    overflow-y: auto;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
  }
  
  .customScrollbar::-webkit-scrollbar {
    width: 0;
    height: 0;
    display: none; /* Chrome, Safari, Opera */
  }